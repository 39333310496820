// application stepped form
$('.step-1-complete').on('click', function() {
  $('.step-1').removeClass('is-current');
  $('.step-2').addClass('is-current');
});

$('.step-2-complete').on('click', function() {
  $('.step-2').removeClass('is-current');
  $('.step-3').addClass('is-current');
});

$('.step-3-back').on('click', function() {
  $('.step-3').removeClass('is-current');
  $('.step-2').addClass('is-current');
});

$('.step-3-complete').on('click', function() {
  $('.step-3').removeClass('is-current');
  $('.step-4').addClass('is-current');
});

$('.step-4-back').on('click', function() {
  $('.step-4').removeClass('is-current');
  $('.step-3').addClass('is-current');
});

$('.step-4-complete').on('click', function() {
  $('.step-4').removeClass('is-current');
  $('.step-5').addClass('is-current');
});
// ACCORDIONS
$('.js--accordion').on('click', function(){

if($(this).children().hasClass('open')) {
  $(this).children().removeClass('open');
} else {
  // $('.accord-title').removeClass('open');
  // $('.accord-content').removeClass('open');
  $(this).children().addClass('open')
}

});
//
// BURGER NAV
function updateMenuButton() {
	$('.header--burger').find('.burger-icon').toggleClass('is-active');
}
$(document).ready(function() {
	$('.header--burger').click(function(e){
		e.preventDefault();
		updateMenuButton();
	});
});
//
// MENU HIDE
$(".header--burger").click(function() {
    $(".nav").toggleClass("show");

    $(this).toggleClass("close");
});
//

// ACCORDION NAV
jQuery(document).ready(function(){
	var accordionsMenu = $('.cd-accordion-menu');

	if( accordionsMenu.length > 0 ) {
		accordionsMenu.each(function(){
			var accordion = $(this);
			//detect change in the input[type="checkbox"] value
			accordion.on('change', 'input[type="checkbox"]', function(){
				var checkbox = $(this);
				// console.log(checkbox.prop('checked'));
				( checkbox.prop('checked') ) ? checkbox.siblings('ul').attr('style', 'display:none;').slideDown(300) : checkbox.siblings('ul').attr('style', 'display:block;').slideUp(300);
			});
		});
	}
});
//

// CUSTOM SELECT
var x, i, j, selElmnt, a, b, c;
/*look for any elements with the class "custom-select":*/
x = document.getElementsByClassName("custom-select");
for (i = 0; i < x.length; i++) {
  selElmnt = x[i].getElementsByTagName("select")[0];
  /*for each element, create a new DIV that will act as the selected item:*/
  a = document.createElement("DIV");
  a.setAttribute("class", "select-selected");
  a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
  x[i].appendChild(a);
  /*for each element, create a new DIV that will contain the option list:*/
  b = document.createElement("DIV");
  b.setAttribute("class", "select-items select-hide");
  for (j = 0; j < selElmnt.length; j++) {
    /*for each option in the original select element,
    create a new DIV that will act as an option item:*/
    c = document.createElement("DIV");
    c.innerHTML = selElmnt.options[j].innerHTML;
    c.addEventListener("click", function(e) {
        /*when an item is clicked, update the original select box,
        and the selected item:*/
        var y, i, k, s, h;
        s = this.parentNode.parentNode.getElementsByTagName("select")[0];
        h = this.parentNode.previousSibling;
        for (i = 0; i < s.length; i++) {
          if (s.options[i].innerHTML == this.innerHTML) {
            s.selectedIndex = i;
            h.innerHTML = this.innerHTML;
            y = this.parentNode.getElementsByClassName("same-as-selected");
            for (k = 0; k < y.length; k++) {
              y[k].removeAttribute("class");
            }
            this.setAttribute("class", "same-as-selected");
            break;
          }
        }
        h.click();
    });
    b.appendChild(c);
  }
  x[i].appendChild(b);
  a.addEventListener("click", function(e) {
      /*when the select box is clicked, close any other select boxes,
      and open/close the current select box:*/
      e.stopPropagation();
      closeAllSelect(this);
      this.nextSibling.classList.toggle("select-hide");
      this.classList.toggle("select-arrow-active");
    });
}
function closeAllSelect(elmnt) {
  /*a function that will close all select boxes in the document,
  except the current select box:*/
  var x, y, i, arrNo = [];
  x = document.getElementsByClassName("select-items");
  y = document.getElementsByClassName("select-selected");
  for (i = 0; i < y.length; i++) {
    if (elmnt == y[i]) {
      arrNo.push(i)
    } else {
      y[i].classList.remove("select-arrow-active");
    }
  }
  for (i = 0; i < x.length; i++) {
    if (arrNo.indexOf(i)) {
      x[i].classList.add("select-hide");
    }
  }
}
/*if the user clicks anywhere outside the select box,
then close all select boxes:*/
document.addEventListener("click", closeAllSelect);
//
